import React from 'react';
import './indexpageCss/IndexWhyChooseUs.css';
const IndexWhyChooseUs = () => {
  return (
    <section className="indexWhyChooseUsSection">
    <div className="innerindexWhyChooseUsSection">

        <div className="firstindexContanerWhycoose">
            <div className="imageindexwhychooseone">
                <div className="imagechooseone">
                    <img src="image/whyus.png" alt="" />
                </div>
            </div>
        </div>

        <div className="secoundindexContanerWhycoose">
            <div className="indewhyChooseText">
                <h2>Why Choose Us</h2>
                <p id="subheadingChoose">CORPORATE EVENT ORGANISER</p>
                <p id="choosePara">Simplifying the struggling process of corporate team building activity ideas, Comfort Your Journey offers an extensive range of indoor and outdoor games that includes ice-breaking exercises for the employees to come together and bond well.</p>
            </div>
            <div className="indexwhychooseusFeaturedbox">
                <div className="indexFeature">
                    <img src="image/icon/handshake.png" alt="" />
                    <h3>Friendly Team</h3>
                    <p>More Then 20+ Teams</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/balloon.png" alt="" />
                    <h3>Perfect Venues</h3>
                    <p>Perfect Venues</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/cheers.png" alt="" />
                    <h3>Unique Scenario</h3>
                    <p>We Think Out Of The Box</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/party.png" alt="" />
                    <h3>Unforgettable Time</h3>
                    <p>We Make You Perfect Event</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/mobile.png" alt="" />
                    <h3>24/7 Support</h3>
                    <p>Anytime Anywhere</p>
                </div>

                <div className="indexFeature">
                    <img src="image/icon/idea.png" alt="" />
                    <h3>Brilliant Ideas</h3>
                    <p>We Have Million Ideas</p>
                </div>


            </div>
        </div>
    </div>
</section>

  )
}

export default IndexWhyChooseUs
