import React, { useEffect, useState } from 'react';
import Error from '../../Error';

const GalleryClientDetailImage = (props) => {
  const data = props.gdatas;
  
  const id = data.length > 0 ? data[0].id : null;
  const [imgdata, setImgData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id) {
      fetch(`https://backend.corporateeventplanner.in/api/cyj-clients-gallery/${id}`)
        .then((response) => response.json())
        .then((result) => {
          setImgData(result);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching images:', error);
          setError(error);
          setLoading(false);
        });
    }
  }, [id]);


  const [showGallery, setShowGallery] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = imgdata.map((img) => img.client_gallery);

  const openGallery = (index) => {
    setCurrentIndex(index);
    setShowGallery(true);
  };

  const closeGallery = () => {
    setShowGallery(false);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <Error />;

  return (
    <>
      <section className="clientDetailesSection">
        <div className="innerclientDetailesSection">
          {images.length === 1 ? (
            <p>No images available.</p>
          ) : (
            images.map((image, index) => (
              <div className="clientDetailCard" key={index} onClick={() => openGallery(index)}>
                <img
                  src={`https://backend.corporateeventplanner.in/storage/client-gallery/${image}`}
                  alt={`Gallery image ${index + 1}`}
                />
              </div>
            ))
          )}
        </div>
      </section>

      {showGallery && images.length > 0 && (
        <div className="GalleryBox">
          <div className="lightImageShow">
            <img
              src={`https://backend.corporateeventplanner.in/storage/client-gallery/${images[currentIndex]}`}
              className="lightboxImage"
              alt={`Gallery image ${currentIndex + 1}`}
            />
          </div>
          <div className="lightboxControl">
            <i
              className="fa-solid fa-chevron-left"
              id="prev"
              onClick={prevImage}
            ></i>
            <i
              className="fa-solid fa-chevron-right"
              id="next"
              onClick={nextImage}
            ></i>
          </div>
          <i
            className="fa-solid fa-xmark"
            id="close"
            onClick={closeGallery}
          ></i>
        </div>
      )}
    </>
  );
};

export default GalleryClientDetailImage;
