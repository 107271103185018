import React from 'react'
import './LocationPageCss/LocationDetail.css';
import LocationDetailLeft from './LocationDetailLeft';
import LocationDetailRight from './LocationDetailRight';
const LocationDetailSection = (props) => {
  const dta = props.data;
  return (
    <div>
<section class="locationpageleftrightSectioon">
    <div class="innerleftrightsection">
   
    <LocationDetailRight  datass={dta} />
    </div>
</section>
    </div>
  )
}

export default LocationDetailSection
