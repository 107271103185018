import React from 'react';
import PageBanner from '../../components/PageBanner';
import GalleryClient from './GalleryClient';
import MetaTag from '../../components/MetaTag';
const GalleryPage = () => {
  return (
    <div>
      <MetaTag noindex={true} titles="Gallery title" metakeywords="Gallery keywords in gurgaon" metaDescriptions="Gallery Description 1" hrefLinks={window.location.href}/>
      <PageBanner image="image/image5.jpg" bannerheading="Gallery" heading="Gallery" pageLink="/gallery" />
      <GalleryClient />
    </div>
  )
}

export default GalleryPage
