import React, {useEffect, useState, useRef} from 'react';
import './indexpageCss/IndexOurUps.css';


const IndexOurUps = () => {
    


const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  }, []);




    let [selectid, setSelectId]=useState(0);

    let shoUpsFeature=(idx)=>{
        setSelectId(idx === selectid ? 1 : idx); 
    }


  return (
    <section className="indexupsSection">
    <div className="innerindexupsSection">
        <div className="indexupsvideoSection">
        <video ref={videoRef} autoPlay muted loop>
            <source src="/Videos/video4.mp4" type="video/mp4" />
        </video>
        </div>
        <div className="indexupstextSection">
            <h2>Our USP</h2>
            <p id="upsSubheading">CORPORATE EVENT ORGANISER</p>
            <p id="upspara">We provide all the materials and equipment related to Corporate Events like Video Conferencing System, Smart Boards, Digital Presenters and other Technical Assistances. We also take care of the accommodations and other facilities for the members of the meetings and conferences in numerous corporate offsite locations. The Corporate Event Venues near Delhi and other locations are well-equipped with latest technology and appropriate seating capacity for the successful execution of your event.</p>

            <div className="ourupsFeatureBox">

                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(0)}>
                        <div className="upshead">
                            <span>1.</span>
                            <h3>High-End Equipments</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 0 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>


                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(1)}>
                        <div className="upshead">
                            <span>2</span>
                            <h3>Team Building Activities</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 1 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>

                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(2)}>
                        <div className="upshead">
                            <span>3</span>
                            <h3>Transportation Services</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 2 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>

                <div className="upsbox">
                    <div className="indourups" onClick={()=>shoUpsFeature(3)}>
                        <div className="upshead">
                            <span>4</span>
                            <h3>Outing Management</h3>
                        </div>
                        <i className="fa-solid fa-circle-arrow-down upsarro"></i>
                    </div>
                    <ul className={`upsfetrd ${selectid === 3 ? '' : 'uactive'}`}>
                        <li><i className="fa-solid fa-circle-check"></i> Digital Audio/Visual Projectors</li>
                        <li><i className="fa-solid fa-circle-check"></i> High Definition Video Cameras</li>
                        <li><i className="fa-solid fa-circle-check"></i> Polycom Speakerphones</li>
                        <li><i className="fa-solid fa-circle-check"></i> HD Audio Quality for better communication</li>
                        <li><i className="fa-solid fa-circle-check"></i> Multi-monitor support</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interoperable with VoIP services</li>
                        <li><i className="fa-solid fa-circle-check"></i> Self-diagnostic & easy troubleshooting</li>
                        <li><i className="fa-solid fa-circle-check"></i> Interactive Display Boards</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</section>
  )
}

export default IndexOurUps;
