import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './indexpageCss/IndexGallery.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import { EffectCube, Autoplay } from 'swiper/modules';

const IndexGallery = () => {

const [cdata, setCdata] = useState([]);

useEffect(()=>{
    const clientimgfetch = async () => {
        try{
            const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-client-gallery');
            const datas = await result.json();
            setCdata(datas);
        }catch(error){
            console.warn(error);
        }
    }
    clientimgfetch();
}, []);



  return (
    <section className="indexgallerySection">
    <div className="innerindexgallerySection">
        <div className="indexFirstgalleryBox">
            <div className="headingGallery">
                <h2>Gallery</h2>
                <p>Our Recent Exposures</p>
                <Link to="gallery" id="indgalleryBtn">View More Photos <i className="fa-solid fa-arrow-right-long"></i></Link>
            </div>
        </div>

        <div className="indexSecoundgalleryBox">
        <Swiper
        effect={'cube'}
        grabCursor={true}
        rewind={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        speed={1000}
        modules={[EffectCube, Autoplay]}
        className="mySwiper idxinergbox">
            {
                cdata
                .map((img, index)=>(
                <SwiperSlide className="indexgalleryImageBox" key={index}>
                    <img src={`https://backend.corporateeventplanner.in/storage/client-gallery/${img.client_gallery}`} alt="" />
                    <div className="clientLogo">
                        <img src={`https://backend.corporateeventplanner.in/storage/client-gallery/${img.logo}`} alt={img.name} />
                    </div>
                </SwiperSlide>
                ))
            }
            

        </Swiper>
        </div>
    </div>
</section>
  )
}

export default IndexGallery
