import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/Header/NavBar";
import Index from "./pages/IndexPage/Index";
import About from "./pages/AboutPage/About";
import Location from './pages/LocationPage/Location';
import LocationDetail from './pages/LocationPage/LocationDetail';
import Service from "./pages/ServicePage/Service";
import ResortPage from './pages/ResortPage/ResortPage';
import Contact from "./pages/ContactPage/Contact";
import GalleryPage from './pages/GalleryPage/GalleryPage';
import GalleryClientDetail from './pages/GalleryPage/GalleryClientDetail';
import Footers from './components/Footer/Footers';
import Weburls from './Weburls';
import India from './pages/LocationPage/India';
import International from './pages/LocationPage/International';
import AllServices from './pages/ServicePage/AllServices';
import Error from './Error';
import ScrollTop from './components/ScrollTop';
function App() {
  
  return (
    <BrowserRouter>
    <ScrollTop />
    <NavBar />
      <Routes>
        <Route path="/" element={<Index/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/location" element={<Location/>}/>
        <Route path="/india" element={<India />} />
        <Route path="/international" element={<International />} />
        <Route path="/location-details" element={<LocationDetail/>}/> 
        <Route path="/service" element={<Service/>}/>
        <Route path="/all-service" element={<AllServices/>}/>
        <Route path="/resorts" element={<ResortPage/>}/>
        <Route path="/gallery" element={<GalleryPage/>}/>
        <Route path="/gallery/:gurl" element={<GalleryClientDetail/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/:urls" element={<Weburls/>}/>
        <Route path="/404" element={<Error/>}/>
      </Routes>
    <Footers /> 
    </BrowserRouter>
  );
}

export default App;
