import React, { useEffect, useState } from 'react';
import './LocationPageCss/LocationDetail.css';
import PageBanner from '../../components/PageBanner';
import LocationDetailAbout from './LocationDetailAbout';
import LocationDetailSection from './LocationDetailSection';
import MetaTag from '../../components/MetaTag';
import Locationvideo from './Locationvideo';
import PageFrom from '../../components/PageFrom';

const LocationDetail = (props) => {
  const urls = props.urls;
  
  // const {urls} = useParams();
  
  const [dta, setData] = useState([]);
  
  useEffect(()=>{
        fetch(`https://backend.corporateeventplanner.in/api/cyj-location/${urls}`)
        .then((response)=>response.json())
        .then((result)=>setData(result))
        .catch((error)=>console.log(error))
  },[urls]);


  const [showForm, setShowForm] = useState(false);

  // Function to open the form
  const openForm = () => {
    setShowForm(true);
  };

  // Function to close the form
  const closeForm = () => {
    setShowForm(false);
  };
  
  const [odta, setOdata]=useState([]);
  const firstId = dta.length > 0 ? dta[0].id : null;
  useEffect(()=>{
   fetch(`https://backend.corporateeventplanner.in/api/cyj-location-other-data/${firstId}`)
     .then((response)=>response.json())
     .then((result)=>setOdata(result))
     .catch((error)=>console.log(error))
   },[firstId]);

   const icons = odta.map(i => i.LocationIcons);
   const iconArrays = Array.from({ length: 9 }, (_, index) => 
     icons.map(x => x[index]?.location_icons)
   );
   const [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8, icon9] = iconArrays;

  return (
    <div>
      <div className="inner-body">
      {
        dta.map((itm, indx)=>(
            <MetaTag noindex={true} titles={itm.metaTitle} metakeywords={itm.metaKeywords} metaDescriptions={itm.metaDescription} hrefLinks={window.location.href}/>
        ))
      }
      

      { dta.map((itm, index) => (
      <div className="locationbanner">
        <div className="location-banner-image">
         <img src={`https://backend.corporateeventplanner.in/storage/location-gallery/${itm.location_banner}`} alt={itm.location}/>
        </div>
        <div className="text-banner" >
          <h1 style={{color:`${itm.lightColor}`}} >#1 Rated <span style={{color:`${itm.darkColor}`}} >Corporate Event Planner</span>  in {itm.location}</h1>
          {/* <p>{itm.heading}</p> */}
          <a onClick={openForm} style={{backgroundColor:`${itm.darkColor}`}} >Get Quote</a>
        </div>
        <img src={"https://backend.corporateeventplanner.in/storage/location-gallery/"+icon1} className='icon1' />
      </div>
       ))
      }
      <LocationDetailAbout data={dta} />
     
      <LocationDetailSection data={dta} />
      <Locationvideo data={dta} />

     
      <img src={"https://backend.corporateeventplanner.in/storage/location-gallery/"+icon2} className='icon2' />
      <img src={"https://backend.corporateeventplanner.in/storage/location-gallery/"+icon3} className='icon3' />
      <img src={"https://backend.corporateeventplanner.in/storage/location-gallery/"+icon4} className='icon4' />
      <img src={"https://backend.corporateeventplanner.in/storage/location-gallery/"+icon5} className='icon5' />
      <img src={"https://backend.corporateeventplanner.in/storage/location-gallery/"+icon6} className='icon6' />

      </div>
      {showForm && (
         <div className="modal">
         <div className="modal-content">
           <span className="closeBtn" onClick={closeForm}>
             &times;
           </span>
      <PageFrom/>
      </div>
      </div>)}
    </div>
  )
}

export default LocationDetail; 
