import React, { useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';


const ResortLeftSection = (props) => {
    const datas = props.data;
    const id = datas.length > 0 ? datas[0].id: null;
   
    const [imagedata, setImagedata]=useState([]);
    
    useEffect(()=>{
        fetch(`https://backend.corporateeventplanner.in/api/cyj-resorts-gallery/${id}`)
        .then((response)=>response.json())
        .then((result)=>setImagedata(result))
        .catch((error)=>console.log(error))
    }, [id]);


 


  return (
<div className="leftresortsSection">
{
    datas.map((itm)=>{
        return <div className="resortsHeaderBox">
        <h2>{itm.resorts_name}</h2>
        <p><i className="fa-solid fa-location-dot"></i> {itm.location}</p>
    </div>
    })
}
{/*  Gallery start  */}
<div className="resortsGalleryBox">
    <Swiper
       centeredSlides={true}
       rewind={true}
       navigation={true}
       autoplay={{
           delay: 2500,
           disableOnInteraction: false,
       }}
       speed={1000}
       modules={[Autoplay, Navigation]}
        classNameName="mySwiper innerResordsGallery">
            {
                imagedata.map((img, index)=>(
                <SwiperSlide className="resortsGallery">
                    <img src={`https://backend.corporateeventplanner.in/storage/resorts-gallery/gallery/${img.resorts_gallery}`} alt=""/>
                </SwiperSlide>
                ))
            }
        
        
    </Swiper>
    {/* <div className="resortGcontrol">
        <i className="fa-solid fa-chevron-left"></i>
        <i className="fa-solid fa-chevron-right"></i>
    </div> */}
</div>
{/*  resorts Overview  */}
<div className="resortsOverviewBox">
    <div className="innerresortsoverview" dangerouslySetInnerHTML={{__html: datas.length > 0 ? datas[0].long_description: null}}></div>
</div>
</div>
  )
}

export default ResortLeftSection;
