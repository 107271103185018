import React, { useEffect, useState } from 'react';
import PageBanner from '../../components/PageBanner';
import './Resorts.css';
import ResortLeftSection from './ResortLeftSection';
import ResortRightSection from './ResortRightSection';
import { useParams } from 'react-router-dom';
import MetaTag from '../../components/MetaTag';

const ResortPage = () => {
  const {urls} = useParams();

  const [data, setData]=useState([]);
  useEffect(()=>{
    fetch(`https://backend.corporateeventplanner.in/api/cyj-resorts/${urls}`)
    .then((response)=>response.json())
    .then((result)=>setData(result))
    .catch((error)=>console.log(error))
  })
  
  return (
    <div>
      {
        data.map((itm, indx)=>(
            <MetaTag key={indx} noindex={true} titles={itm.metaTitle} metakeywords={itm.metaKeywords} metaDescriptions={itm.metaDescription} hrefLinks={window.location.href}/>
        ))
      }
       <PageBanner image={`https://backend.corporateeventplanner.in/storage/resorts-gallery/${data.length > 0 ? data[0].resorts_banner:null}`} heading={data.length > 0 ? data[0].resorts_name:null} />
    <section class="resortsaboutSection">
        <div class="innerresortsaboutSection">
            <ResortLeftSection data={data}/>
            <ResortRightSection />

        </div>
    </section>
    </div>
  )
}

export default ResortPage
