import React from 'react'
import ContactBanner from './ContactBanner';
import ContactForm from './ContactForm';
import './Contact.css';
import MetaTag from '../../components/MetaTag';
const Contact = () => {
  return (
    <>
    <MetaTag noindex={true} titles="Contact title" metakeywords="Contact keywords in gurgaon" metaDescriptions="Contact Description 1" hrefLinks={window.location.href}/>
    <ContactBanner />
    <ContactForm />
    </>
  )
}

export default Contact
