import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const LocationDetailLeft = (props) => {
    const datas = props.datass;
    const id = datas.length > 0 ? datas[0].id : null;

    const [rdata, setRdata] = useState([]);

    useEffect(() => {
        if (id) {
            fetch(`https://backend.corporateeventplanner.in/api/cyj-resorts-data/${id}`)
                .then((response) => response.json())
                .then((result) => setRdata(result))
                .catch((error) => console.log(error));
        }
    }, [id]);

    // If no data, return display:none for locationleftSection
    const shouldHideSection = !datas || datas.length === 0 || rdata.length === 0;

    const darkColor = datas.length > 0 ? datas[0].darkColor : null;

    return (
        <div className="locationleftSection" style={{ display: shouldHideSection ? 'none' : 'block' }}>
            <div className="locationResortsBox">
                <div className="lokreortheadings">
                    <h2 style={{ color: `${darkColor}` }}>
                        Resorts in {datas.map(L => L.location)} for Corporate Events
                    </h2>
                    {/* <p><i className="fa-solid fa-location-dot"></i> {datas.map(L=>(L.location))}</p> */}
                </div>
                <div className="lokresortBoxs">
                    {rdata.map((itm, index) => (
                        <div className="locationResortCards" key={index}>
                            <div className="lokresrtImg">
                                <img src={`https://backend.corporateeventplanner.in/storage/resorts-gallery/${itm.resorts_banner}`} alt={itm.resorts_name} />
                            </div>
                            <div className="lokreortText">
                                <h3>{itm.resorts_name}</h3>
                                <p>{itm.small_description}</p>
                                <div className="links-resort">
                                    <Link to={`../${itm.resort_url}`}>View More</Link>
                                    <a href='tel:8130781111'>Contact</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocationDetailLeft;
