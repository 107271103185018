import React from 'react'
import './ServicePageCss/ServiceLeftRight.css';
import ServiceLeftSection from './ServiceLeftSection';
import ServiceRightSection from './ServiceRightSection';
const ServiceSection = (props) => {
  const datas = props.longtext;
  return (
    <>
<section className="servicepageleftrightSectioon">
    <div className="innerleftrightsection">
        <ServiceLeftSection textdata = {datas}/>
        <ServiceRightSection />
    </div>
</section>
    </>
  )
}

export default ServiceSection;
