import React from 'react';
import './AboutPageCss/AboutPageVission.css';
const AboutPageVission = () => {
  return (
    <section className="vissionSection">
    <div className="innervissionSection">
        <div className="Heading">
            <span className="topheading">Our</span>
            <div className="sbheadings">
                <p>CYJ IS THE</p>
                <h2>Goal</h2>
                <p>BEHIND YOUR EVENT</p>
            </div>
        </div>

        <div className="missionvisionbox">
            <div className="goalbox">
                <div className="goalhead">
                    <i className="fa-solid fa-bullseye"></i>
                    <h3>Mission</h3>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt eaque ipsam quod blanditiis numquam sunt soluta ipsa voluptas ullam, laborum tempora natus possimus, commodi dolor est omnis vitae. Necessitatibus velit aliquam voluptatibus facilis error maiores sint suscipit soluta vitae odio laborum fugit, dolorum porro, vero hic in? Hic, sunt quos!</p>
            </div>
            <div className="goalbox">
                <div className="goalhead">
                <i className="fa-solid fa-eye"></i>
                <h3>Vision</h3>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt eaque ipsam quod blanditiis numquam sunt soluta ipsa voluptas ullam, laborum tempora natus possimus, commodi dolor est omnis vitae. Necessitatibus velit aliquam voluptatibus facilis error maiores sint suscipit soluta vitae odio laborum fugit, dolorum porro, vero hic in? Hic, sunt quos!</p>
            </div>

            <div className="goalbox">
                <div className="goalhead">
                <i className="fa-solid fa-gem"></i>
                <h3>Values</h3>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt eaque ipsam quod blanditiis numquam sunt soluta ipsa voluptas ullam, laborum tempora natus possimus, commodi dolor est omnis vitae. Necessitatibus velit aliquam voluptatibus facilis error maiores sint suscipit soluta vitae odio laborum fugit, dolorum porro, vero hic in? Hic, sunt quos!</p>
            </div>
        </div>
    </div>
</section>


  )
}

export default AboutPageVission
