import React, {useState, useRef, useEffect} from 'react'
import './ServicePageCss/ServiceAbout.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { EffectCube, Navigation, Autoplay } from 'swiper/modules';
const ServiceAbout = (props) => {
    const abdta = props.aboutdata;
    const id = abdta.length > 0 ? abdta[0].id : null; 
    
    const [gdata, setGdata] = useState([]);
    useEffect(()=>{
        fetch(`https://backend.corporateeventplanner.in/api/cyj-service-gallery/${id}`)
        .then((response)=>response.json())
        .then((result)=>setGdata(result))
        .catch((error)=>console.log(error))
    }, [id]);

  return (
   <>
    <section className="serviceaboutSection">
    <div className="innerserviceaboutSection">
        <div className="serviceaboutContaner">

            <div className="servicepageaboutText">
                <h2>{abdta.length > 0 ? abdta[0].heading : null }</h2>
                <div className='innertext' dangerouslySetInnerHTML={{__html: abdta.length > 0 ? abdta[0].service_info : null }}></div>
            </div>

            <div className="imageserviceabout">
                <Swiper
        effect={'Cube'}
        spaceBetween={30}
        grabCursor={true}
        navigation={true}
        cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
        autoplay={{
            delay: 100500,
            disableOnInteraction: false,
        }}
        speed={1000}
        modules={[EffectCube, Navigation,  Autoplay]}
        className="mySwiper innerimageserviceabout">
            {
                gdata.map((img, index)=>(
                    <SwiperSlide className="serabotimage" key={index}>
                        <img src={`https://backend.corporateeventplanner.in/storage/service_gallery/gallery/${img.service_gallery}`}/>
                    </SwiperSlide>
                ))
            }
                    
                    
                </Swiper>
                
            </div>

        </div>
    </div>
</section>
   </>
  )
}

export default ServiceAbout
