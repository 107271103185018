import React, { useEffect, useRef, useState } from 'react'
import PageFrom from '../../components/PageFrom';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
// import required modules
import { Autoplay, EffectCreative } from 'swiper/modules';

const ServiceRightSection = () => {

  const [data, setData]=useState([]);

useEffect(() => {

  fetch(`https://backend.corporateeventplanner.in/api/cyj-service-slider`)
    
    .then((response) => response.json())
    .then((result) => {
      setData(result);
    })
    .catch((error) => {
      console.error(error);
    });
}, []);


  return (
<div className="rightSection">
    <div className="rightServiceSlidBanner">
    <h2>MICE Services</h2>
    <Swiper
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        autoplay={{
            delay: 2000,
            disableOnInteraction: false,
        }}
        speed={1500}
        modules={[EffectCreative, Autoplay]}
        className="mySwiper innerrightServiceSlidBanner">


          {
            data.map((img, index)=>(
              <SwiperSlide className="rightserslidimgb" key={index}>
                <img src={`https://backend.corporateeventplanner.in/storage/service_gallery/${img.serviceimage}`} />
              </SwiperSlide>
            ))
          }
          
    </Swiper>
</div>
<PageFrom />
</div>
  )
}

export default ServiceRightSection
