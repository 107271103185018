import React, { useEffect, useState } from 'react';

const ServiceLeftSection = (props) => {
  const data = props.textdata;
  const firstId = data.length > 0 ? data[0].id : null;
  const [dta, setDta]=useState([]);

  useEffect(()=>{
    fetch(`https://backend.corporateeventplanner.in/api/cyj-service`)
    .then((response)=>response.json())
     .then((result)=>setDta(result))
     .catch((error)=>console.log(error))
  }, [firstId]);
 

  return (
    <div className="leftSection">
       <h2>What We Offer</h2>
      <div className="service-list-container">
      {
        dta.map((item, index) => (
          firstId === item.id ?
          item.subServiceImage.map((subItem, subIndex) => (
            <div className="service-list" key={`${index}-${subIndex}`}>
              <div className="service-list-image">
                <img src={'https://backend.corporateeventplanner.in/storage/service_gallery/'+subItem.sub_service_image } alt={subItem.sub_service_heading || 'Service Image'} />
                <h3>{item.service}</h3>
              </div>
              <h4>{subItem.sub_service_heading || 'Annual General Meeting'}</h4>
              <p>{subItem.sub_service_description || 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cupiditate veniam enim sit mollitia deleniti distinctio fugit nam itaque asperiores, facere eligendi maxime natus quidem esse quasi qui similique omnis adipisci.'}</p>
            </div>
          ))
          : null
        ))
      }


      </div>
    <div className="serviceTextTwo" dangerouslySetInnerHTML={{__html: data.length > 0 ? data[0].long_description : null}}></div>
</div>
  )
}

export default ServiceLeftSection
