import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './PageFrom.css';

const PageFrom = () => {

  let urlsGet = window.location.href;
    
  const [errors, setError] = useState('');
  const [formData, setFormData] = useState({
      name: '',
      mobile: '',
      email: '',
      location: '',
      subject: '',
      message: '',
      pageUrl: urlsGet,
  });

  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const result = await axios.post('https://backend.corporateeventplanner.in/api/mail-smtp', formData);
          Swal.fire(result.data.success);
      } catch (error) {
          if (error.response) {
              setError(error.response.data.errors);
              
          } else {
              setError('An error occurred. Please try again later.');
          }
      }
  };

  return (
    <>
   
<form onSubmit={handleSubmit} class="PageForm">
    <h2>Send your query</h2>
    <img src="image/image5.jpg" alt=""/>
    <h3>Elevate Your Events with Our Premier Corporate Venues.</h3>
    <input type="text" name="name" value={formData.name} placeholder="Name" onChange={handleChange}/>
    <p style={{color:'red', fontSize:'.8rem', marginLeft:'.5rem'}}>{errors.name}</p>
    <input type="text" name="mobile" value={formData.mobile} placeholder="Mobile" onChange={handleChange}/>
    <p style={{color:'red', fontSize:'.8rem', marginLeft:'.5rem'}}>{errors.mobile}</p>
    <input type="email" name="email" value={formData.email} placeholder="Email" onChange={handleChange}/>
    <p style={{color:'red', fontSize:'.8rem', marginLeft:'.5rem'}}>{errors.email}</p>
    <input type="text" name="location" value={formData.location} placeholder="Location" onChange={handleChange}/>
    <p style={{color:'red', fontSize:'.8rem', marginLeft:'.5rem'}}>{errors.location}</p>
    <input type="text" name="subject" value={formData.subject} placeholder="Subject" onChange={handleChange}/>
    <textarea placeholder="Message here..." name="message" value={formData.message} onChange={handleChange}/>
    <input type="hidden" name="pageUrl" value={formData.pageUrl} onChange={handleChange}/>
    <button id="pageQuery">Send Query</button>
</form>
    </>
  )
}

export default PageFrom
