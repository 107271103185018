import React, { useEffect } from 'react'
import PageBanner from '../../components/PageBanner';
import AboutPageInfo from './AboutPageInfo';
import IndexOurUps from '../IndexPage/IndexOurUps';
import AboutPageVission from './AboutPageVission';
import AboutPageClient from './AboutPageClient';
import './AboutPageCss/AboutDevice.css';
import MetaTag from '../../components/MetaTag';
const About = () => {

  return (
    <div style={{backgroundColor:'#2E073F'}} >
    <MetaTag noindex={true} titles="About title" metakeywords="About keywords in gurgaon" metaDescriptions="About Description 1" hrefLinks={window.location.href}/>
    <PageBanner image="image/image5.jpg" bannerheading="About Us" heading="About Us" pageLink="/about" />
    <AboutPageInfo />
    <IndexOurUps />
    <AboutPageVission />
    <AboutPageClient />
    </div>
  )
}

export default About
