import React from 'react';
import {Helmet} from "react-helmet";
const MetaTag = (props) => {
    let noindex = props.noindex;
    let title = props.titles;
    let metakeyword = props.metakeywords;
    let metaDescription = props.metaDescriptions;
    let hrefLink = props.hrefLinks;

    
  return (
   
      <Helmet>
        {noindex === true && <meta name="robots" content="noindex" />}
        
        <title>{title}</title>
        <meta
        name="description"
        content={metaDescription}
        />
        <meta
        name="keyword"
        content={metakeyword}
        />
        <link rel="canonical" href={hrefLink} />
    </Helmet>
    
  )
}

export default MetaTag
