import React, { useEffect, useState } from 'react';
import './indexpageCss/Indexnewbanner.css'




function Indexnewbanner() {

    const [serdta, setServdata]=useState([]);
useEffect(()=>{
    const servicedata = async()=>{
        try{
            const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-service');
            const data = await result.json();
            setServdata(data);
        }catch(error){
            console.log(error);
        }
    }
    servicedata();
}, []);





  return (
    <div className='newbanner' >
      <div className="banner-text">
        {/* <h1>your preferred offsite planner.</h1> */}
        <img src='image/text-head.png'/>
        <h3>We are one of the fastest growing offsite & retreats planning agencies in India. Serving 30+ MNC clients to ensure their team is going to have an awesome experience. Our offsites are unplugged, bespoke and highly customized to ensure each and every guest is engaged and there is something for everyone.</h3>
        <a class="glow-on-hover" href='#' >Explore Corporate Offsite</a>
      </div>
  <div className="banner-round">
    <div className="carousel">
      <div className="carousel-control-button left">
        <input type="radio" name="carousel-control-input" />
      </div>
      <div className="carousel-control-button right">
        <input type="radio" name="carousel-control-input" defaultChecked />
      </div>

      <div className="carousel-rotation-direction">
        {/* <ul className="carousel-item-wrapper" style={{ '--_num-elements': 12 }}>
          
          <li className="carousel-item" style={{ '--_index': 1, '--_image-url': `url('../image/Conference-and-Seminar.jpg')` }}>
            <a href="#" target="_blank" rel="noopener noreferrer">Architecture Example 1</a>
          </li> */}
           <ul className="carousel-item-wrapper" style={{ '--_num-elements': 10 }}>
             
           {

              serdta.slice(0, 10).map((serv, index) => (
                serv.image
                  .filter(i => i.vertical_banner !== undefined)
                  .map(i => <li className="carousel-item" style={{ '--_index': `${index}`, '--_image-url': `url(https://backend.corporateeventplanner.in/storage/service_gallery/${i.vertical_banner})` }} key={index}><a href={serv.service_url} >{serv.service}</a> </li>
                    
                  )
              ))
                     
            }  

                            {/* <li className="carousel-item" style={{ '--_index': 11, '--_image-url': `` }}>
                                <a href="#" target="_blank" rel="noopener noreferrer">
                                   <p> View all</p>
                                </a>
                            </li> */}
                    </ul>  
          
          {/* Add more carousel items as needed */}
       
      </div>
    </div>
    </div>
    </div>
  )
}

export default Indexnewbanner
