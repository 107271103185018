import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
const ContactForm = () => {

    let urlsGet = window.location.href;
    
    const [errors, setError] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        location: '',
        subject: '',
        message: '',
        pageUrl: urlsGet,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await axios.post('https://backend.corporateeventplanner.in/api/mail-smtp', formData);
            Swal.fire(result.data.success)
            .then((r) => {
                if (r.isConfirmed) {
                    
                    document.querySelectorAll('.allpageform input, .allpageform textarea').forEach(element => {
                        element.value = '';
                    });
                    window.location.reload();
                }
            });

        } catch (error) {
            if (error.response) {
                setError(error.response.data.errors);
                
            } else {
                setError('An error occurred. Please try again later.');
            }
        }
    };

  return (
    <section className="contactpagesection" style={{background: `url("image/image1.jpg")`, backgroundAttachment:'fixed', backgroundRepeat:'no-repeat', backgroundPosition:'bottom', backgroundSize:'cover'}}>

    <div className="innercontactpagesection">
        <div className="contactcallout">
            <i className="fa-solid fa-phone"></i>
            <NavLink to="">8586828846</NavLink>
        </div>
        <div className="leftcontactbox">
            <div className="contlefthead">
                <h2>Let’s Talk</h2>
                <p>COME TO CYJ AND HAVE YOUR DREAM EVENT HANDLED</p>
            </div>
            <address>
                <b>Address :</b> Comfort Your Journey Pvt. Ltd., 3nd Floor, Sector 55, Gurgaon - 122002
             </address>
             <NavLink to="tel:88888888"><i className="fa-solid fa-phone"></i> +91-8586828846</NavLink>
             <NavLink to="mailto:info@corporateevents.com"><i className="fa-solid fa-envelope"></i> info@corporateevents.com</NavLink>
            <ul>
                <li><NavLink to=""></NavLink><i className="fa-brands fa-twitter"></i></li>
                <li><NavLink to=""></NavLink><i className="fa-brands fa-instagram"></i></li>
                <li><NavLink to=""></NavLink><i className="fa-brands fa-facebook-f"></i></li>
                <li><NavLink to=""></NavLink><i className="fa-brands fa-linkedin-in"></i></li>
            </ul>
            <iframe src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d28067.652000533475!2d77.1118625!3d28.4356522!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390d1f4d25d98837%3A0x3909d1b9381f9a3c!2sComfort%20Your%20Journey%20Pvt.%20Ltd.%20%23308%2C%20Third%20Floor%2C%20Suncity%20Arcade%20Sector%2054%20Gurugram%2C%20Haryana%20122003!3m2!1d28.4356522!2d77.1118625!5e0!3m2!1sen!2sin!4v1705473157597!5m2!1sen!2sin" id="mapifram" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="rightcontactBox">
            <form onSubmit={handleSubmit} className='allpageform'>
                <input type="text" name="name" value={formData.name} placeholder="Name" onChange={handleChange}/>
                <p style={{color:'#fff'}}>{errors.name}</p>
                <input type="text" name="mobile" value={formData.mobile} placeholder="Mobile" onChange={handleChange}/>
                <p style={{color:'#fff'}}>{errors.mobile}</p>
                <input type="email" name="email" value={formData.email} placeholder="Email" onChange={handleChange}/>
                <p style={{color:'#fff'}}>{errors.email}</p>
                <input type="text" name="location" value={formData.location} placeholder="Location" onChange={handleChange}/>
                <p style={{color:'#fff'}}>{errors.location}</p>
                <input type="text" name="subject" value={formData.sbj} placeholder="Subject" onChange={handleChange}/>
                <textarea placeholder="Message here..." name="message" value={formData.msg} onChange={handleChange}/>
                <input type="hidden" name="pageUrl" value={formData.pageUrl} onChange={handleChange}/>
                <button>Send Query <i className="fa-solid fa-arrow-right-long"></i></button>
            </form>
        </div>
    </div>
</section>
  )
}

export default ContactForm;
