import React, { useEffect, useRef, useState } from "react";
import PageFrom from "../../components/PageFrom";
import { Link } from "react-router-dom";
import "./LocationPageCss/Locationpin.css";

const LocationDetailRight = (props) => {
  const cntryValue = props.datass.map((itm) => itm.country);

  const [lokdata, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const lokfetch = async () => {
      try {
        const result = await fetch(
          "https://backend.corporateeventplanner.in/api/cyj-location"
        );
        const data = await result.json();
        setIsLoading(false);
        setData(data);
      } catch (error) {
        console.warn(error);
      }
    };
    lokfetch();
  }, []);
  if (isLoading) {
    <p>Loading...</p>;
  }

  const [ldatas, setDatas] = useState([]);
  const [ldta, setLdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await Promise.all(
          lokdata.map(async (item) => {
            try {
              const response = await fetch(
                `https://backend.corporateeventplanner.in/api/cyj-location-other-data/${item.id}`
              );
              const result = await response.json();
              return {
                location_id: item.id,
                thumbnailImg: result,
              };
            } catch (error) {
              console.error(`Failed to fetch data for id ${item.id}:`, error);
              return null;
            }
          })
        );
        setLdata(results.filter((result) => result !== null));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (lokdata.length > 0) {
      fetchData();
    }
  }, [lokdata]);

  let locations = lokdata.map((item) => ({
    id: item.id,
    location: item.location,
    category: item.country,
    location_url: item.location_url,
    image: `https://backend.corporateeventplanner.in/storage/location-gallery/${item.location_banner}`,
  }));

  const darkcolor = ldatas.length > 0 ? ldatas[0].darkcolor : null;

  return (
    <div className="locationrightSection">
      <div className="locationdetail-page-head">
        <h5 style={{color:`${darkcolor}`}} >More </h5>
        <h4>Locations</h4>
      </div>

      <div className="all-location-pin">
        {locations.map(
          (location, index) =>
            location.category == cntryValue && (
              <Link
                to={"../" + location.location_url}
                className="locationpin"
                key={index}
              >
                <div className="locationpinImage">
                  {ldta
                    .filter((i) => i.location_id === location.id)
                    .flatMap((i) => i.thumbnailImg)
                    .flatMap((y) => y.ThumbnailImage)
                    .map((x, idx) => (
                      <img
                        key={idx} // Use index as key here
                        src={`https://backend.corporateeventplanner.in/storage/location-gallery/${x.location_thumbnail}`}
                        alt={location.location}
                      />
                    ))}
                </div>
                <div className="loklinkpintext">
                  <h3>{location.location}</h3>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};

export default LocationDetailRight;
