import React, {useEffect, useState, useRef} from 'react';
import './indexpageCss/IndexBanner.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';

import { Autoplay, EffectCards } from 'swiper/modules';
import { Link } from 'react-router-dom';

const IndexBanner = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  }, []);

  return (
    <>
    
      <div className="indexbannercontaner">
        <div className="innerbannercontainer">
            <div className="videoIndexbanner">
              <video ref={videoRef} autoPlay muted loop className='videobanner'>
                  <source src="/Videos/video5.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="indexbannertextslideimg">

            <div className="textIndexbanner">
                <h3>Corporate Event Planner</h3>
                <h2>CYJ EVENTS</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea est sequi sapiente perferendis possimus libero aliquid nemo? Recusandae deserunt laudantium nam ipsam dignissimos, at, natus ut enim quas nulla et!</p>
                <div className="indxbanrbtn">
                  <Link to="contact">Get in Touch <i className="fa-solid fa-circle-arrow-right"></i></Link>
                  <Link to=""><i className="fa-solid fa-play"></i> Watch Video</Link>
                </div>
            </div>

            </div>
        </div>
      </div>
    </>

  )
}

export default IndexBanner;
