import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LocationDetail from './pages/LocationPage/LocationDetail';
import ResortPage from './pages/ResortPage/ResortPage';
import Service from './pages/ServicePage/Service';
import Error from './Error';
const Weburls = () => {
    const { urls } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    useEffect(() => {
        fetch(`https://backend.corporateeventplanner.in/api/allurls/${urls}`)
            .then((response) => response.json())
            .then((result) => {
                setData(result);
                setLoading(false);
                setShowError(true);
            })
            .catch((error) =>{
                console.log(error);
                setLoading(false);
                setShowError(true);
            });
    }, [urls]); 

    return (
        <div>
            {
  loading ? (
    <p>Loading...</p>
  ) : (
    <>
      {data.length > 0 ? (
        data.map((item, index) => {
          switch (item.table_name) {
            case 'location':
              return <LocationDetail key={index} urls={item.urls} />;
            case 'service':
              return <Service key={index} urls={item.urls} />;
            case 'resorts':
              return <ResortPage key={index} urls={item.urls} />;
            default:
              return null;
          }
        })
      ) : (
        showError && <Error />
      )}
    </>
  )
}

           
        </div>
    );
};

export default Weburls;
