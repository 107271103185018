import React from 'react';
import { NavLink } from 'react-router-dom';
import './PageBanner.css';

const PageBanner = (props) => {
  return (
    <section className="pagesBannerDesignBox">
      <div className="innerpagesBannerDesignBox">
        <div className="imagepagesBox">
          <div className="imagespages">
            <img src={props.image} alt={props.bannerheading} />
          </div>
        </div>
      </div>
      <div className="pagesesText">
        <h1>{props.bannerheading}</h1>
        <ul>
          <li><NavLink to="/">Home</NavLink></li>
          <li>|</li>
          <li><NavLink to={props.pageLink}>{props.heading}</NavLink></li>
          {props.client && (
            <>
              <li>|</li>
              <li><NavLink to={props.clientLink}>{props.client}</NavLink></li>
            </>
          )}
        </ul>
      </div>
    </section>
  );
}

export default PageBanner;
