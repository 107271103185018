import React, { useEffect, useState } from 'react';
import './GalleryPageCss/GalleryClient.css';
import { Link } from 'react-router-dom';
const GalleryClient = () => {
const [data, setData]=useState([]);
  useEffect(()=>{
    fetch(`https://backend.corporateeventplanner.in/api/cyj-client-gallery`)
    .then((response)=>response.json())
    .then((result)=>setData(result))
    .catch((error)=>console.log(error))
  },[]);
  return (
    <section className="gallerypageContaner">
    <div className="innergallerypageContaner">
        <div className="clientlogo">

          {
               data.map((logo, index)=>(
                    <Link key={index} to={`../gallery/${logo.curl}`} className="clienGcard">
                         <img src={`https://backend.corporateeventplanner.in/storage/client-gallery/${logo.logo}`} alt={logo.name} />
                    </Link>
               ))
          }
           
           

        </div>

    </div>
</section>
  )
}

export default GalleryClient;
