import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay } from 'swiper/modules';
import './indexpageCss/IndexTestimonial.css';
const IndexTestimonial = () => {
    let slidesPerView;
    var screenWidth = window.innerWidth;

    if (screenWidth >= 1050) {
      slidesPerView = 4;
    } else if (screenWidth >= 750) {
        slidesPerView = 3;
    } else if (screenWidth >= 650) {
        slidesPerView = 2;
    } else {
        slidesPerView = 1;
    }


  return (
    <section className="testimonialSectionSlider">
    <div className="innerTestimonialSlider">

        <div className="Heading">
            <span className="topheading">Our Satisfied</span>
            <div className="sbheadings">
                <p>WHAT</p>
                <h2>Clients</h2>
                <p>SAY ABOUT US</p>
            </div>
        </div>

        <div className="testimaincontanerslide">
        <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        speed={1000}
        modules={[Autoplay]}
        className="mySwiper testimonialSlideBox">
            {/* testimonial Cards start */}
            <SwiperSlide className="slideTesimonial">
                <div className="messageBox">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi quas, explicabo, quisquam accusantium ducimus iusto illo animi vel debitis esse harum laudantium nisi repudiandae dignissimos.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <img src="image/profile.jpg" alt="" />
                    </div>
                    <h3>Pardeep Sharma</h3>
                </div>
            </SwiperSlide>


            <SwiperSlide className="slideTesimonial">
                <div className="messageBox">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi quas, explicabo, quisquam accusantium ducimus iusto illo animi vel debitis esse harum laudantium nisi repudiandae dignissimos.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <img src="image/profile.jpg" alt="" />
                    </div>
                    <h3>Pardeep Sharma</h3>
                </div>
            </SwiperSlide>


            <SwiperSlide className="slideTesimonial">
                <div className="messageBox">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi quas, explicabo, quisquam accusantium ducimus iusto illo animi vel debitis esse harum laudantium nisi repudiandae dignissimos.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <img src="image/profile.jpg" alt="" />
                    </div>
                    <h3>Pardeep Sharma</h3>
                </div>
            </SwiperSlide>

            <SwiperSlide className="slideTesimonial">
                <div className="messageBox">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi quas, explicabo, quisquam accusantium ducimus iusto illo animi vel debitis esse harum laudantium nisi repudiandae dignissimos.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <img src="image/profile.jpg" alt="" />
                    </div>
                    <h3>Pardeep Sharma</h3>
                </div>
            </SwiperSlide>

            <SwiperSlide className="slideTesimonial">
                <div className="messageBox">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi quas, explicabo, quisquam accusantium ducimus iusto illo animi vel debitis esse harum laudantium nisi repudiandae dignissimos.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <img src="image/profile.jpg" alt="" />
                    </div>
                    <h3>Pardeep Sharma</h3>
                </div>
            </SwiperSlide>


            <div className="slideTesimonial swiper-slide">
                <div className="messageBox">
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi quas, explicabo, quisquam accusantium ducimus iusto illo animi vel debitis esse harum laudantium nisi repudiandae dignissimos.</p>
                    <span className="arrowIconMessg"></span>
                </div>
                <div className="clientprofilebox">
                    <div className="profile">
                        <img src="image/profile.jpg" alt="" />
                    </div>
                    <h3>Pardeep Sharma</h3>
                </div>
            </div>

        </Swiper>
             {/* testimonial Cards End */}

        </div>
    </div>
</section>
  )
}

export default IndexTestimonial
